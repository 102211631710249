// import './cms';

import {log} from './libs/util';

// Hello log
const init = () => {
  log();
};

init();
